import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"

const SingleBlog = ({ pageContext, data, location }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { content } = data.allMarkdownJson.nodes[0]

  const noindex = pageContext.title === "wtc/" ? "noindex" : ""

  return (
    <Layout className="single-markdown-page" navSpacer>
      <SEO
        robots={noindex}
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <Section smallSpacing>
        <Container>
          <Text text={content} />
        </Container>
      </Section>
    </Layout>
  )
}

export const MarkownPage = graphql`
  query singleMarkownPage($title: String!) {
    allMarkdownJson(filter: { title: { eq: $title } }) {
      nodes {
        content
      }
    }
  }
`

export default SingleBlog
